import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: function () {
      return import('@/views/Index.vue')
    }
  },
  {
    path: '/peliculas/:id?',
    name: 'Peliculas',
    component: function () {
      return import('@/views/Peliculas.vue')
    }
  },
  {
    path: '/directores/:id?',
    name: 'Directores',
    component: function () {
      return import('@/views/Directores.vue')
    }
  },
  {
    path: '/actores/:id?',
    name: 'Actores',
    component: function () {
      return import('@/views/Actores.vue')
    }
  },
  {
    path: '/categorias/:id?',
    name: 'Categorias',
    component: function () {
      return import('@/views/Categorias.vue')
    }
  },
  {
    path: '/productoras/:id?',
    name: 'Productoras',
    component: function () {
      return import('@/views/Productoras.vue')
    }
  },
  {
    path: '/familias/:id?',
    name: 'Familias',
    component: function () {
      return import('@/views/Familias.vue')
    }
  },
  {
    path: '/usuarios/:id?',
    name: 'Usuarios',
    component: function () {
      return import('@/views/Usuarios.vue')
    }
  }, 
  {
    path: '/premiums',
    name: 'Premiums',
    component: function () {
      return import('@/views/Premiums.vue')
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: function () {
      return import('@/views/Login.vue')
    }
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
