import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import axios from 'axios'
import VueGtag from "vue-gtag";

if (process.env.VUE_APP_GTAG) {
  Vue.use(VueGtag, {
      config: { id: "G-71PLR1F1LD" }
  }, router);
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initialStore');
	},
  render: function (h) { return h(App) }
}).$mount('#app')
import '@/assets/stylo.css'
