import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null
    },
    mutations: {
        login: (state, connections) => {
            localStorage.setItem('user', connections);
            state.user = connections;
        },
        logout: (state) => {
            localStorage.removeItem('user');
            state.user = null;
        },
        initialStore(state){
            if(localStorage.getItem('user'))
                state.user = localStorage.getItem('user');
        },
    },
    actions: {
    },
    modules: {
    }
})
