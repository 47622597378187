<template>
    <div id="app">
        <div class="main_message info_message_wrapper" v-if="error.type">
            <div class="info_message ok" :class="{ok: error.type==1, ko: error.type==2, info: error.type==3}" @click="error={}" v-html="error.msg">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg>
                </span>
            </div>
        </div>
        <header class="header" v-if="$store.state.user && user">
            <div class="logo_wrapper">
                <router-link to="/">
                    <img src="@/assets/images/logo.png">
                </router-link>
            </div>
            <div class="header_block header_nav">
                <div class="header_nav_btn">
                    <span>{{user.username}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"/></svg>
                </div>
                <div class="header_nav_btn" @click="logout()">
                    <span>Salir</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-2 10v-.083c-1.178.685-2.542 1.083-4 1.083-4.411 0-8-3.589-8-8s3.589-8 8-8c1.458 0 2.822.398 4 1.083v-2.245c-1.226-.536-2.577-.838-4-.838-5.522 0-10 4.477-10 10s4.478 10 10 10c1.423 0 2.774-.302 4-.838v-2.162z"/></svg>
                </div>
                <div class="menu_trigger" :class="{close_trigger: menu}" @click="menu=!menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
        <Sidebar :menu="menu" v-if="$store.state.user && user"/>
        <router-view />
    </div>
</template>

<script>
import axios from 'axios';
import Sidebar from '@/components/Sidebar.vue'

export default {
    name: 'App',
    components: {
        Sidebar
    },
    data: function(){
        return{
            menu: false,
            error: {},
            modal: false,
            user: null
        }
    },
    methods: {
        getUser: function(){
            if(!this.$store.state.user){
                if(this.$route.name != 'Login')
                    this.$router.push('login');
                return;
            }
            axios
                .get(process.env.VUE_APP_URL+'adminfind', {
                    params: {
                        token: this.$store.state.user
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.user = rs.data;
                        this.$store.commit('login', this.user.token);
                    }
                    else{
                        this.$store.commit('logout');
                        window.location.reload();
                    }
                })
                .catch(error => {
                    this.$store.commit('logout');
                    window.location.reload();
                });
        },
        logout: function(){
            axios
                .post(process.env.VUE_APP_URL+'adminlogout', {
                    token: this.$store.state.user
                })
                .then(response => {
                    let rs = response.data;
                    //if(rs.state == 'OK'){
                        //window.location.reload();
                        this.$router.go(0)
                    //}
                })
                .finally(() => {
                    this.$store.commit('logout');
                    this.$router.go(0);
                })
        },
    },
    watch: {
        error: function(newval, oldval){
            /*if(oldval.type && !newval){
                this.error.type == oldval.type;
                setTimeout(() => {
                    this.error = {};
                }, 1000);
            }
            else if(!oldval && newval.type){
                setTimeout(() => {
                    this.error = {};
                }, 5000);
            }*/
            if(newval)
                setTimeout(() => {
                    this.error = {};
                }, 5000);
        },
        $route: function(){
            this.modal = false;
            this.getUser();
        }
    },
    created: function(){
        this.getUser();
    },
    mounted: function(){
        this.getUser();
    }
}
</script>

<style>
    .modal-enter-active, .modal-leave-active {
        transition: opacity .5s;
    }
    .modal-enter, .modal-leave-to{
        opacity: 0;
    }
</style>